import { useState } from "react";

const useModal = () => {
  const [isShowing, setIsShowing] = useState({
    disableDevice: false,
    removeDevice: false,
    settings: false,
    info: false
  });

  function toggle(modalName) {

    setIsShowing({
      ...isShowing,
      ...{ [modalName]: !isShowing[modalName] }
    });
  }

  function closeAll() {
    for (const key in isShowing) {
      isShowing[key] = false;
    }
    setIsShowing(isShowing);
  }

  return {
    isShowing,
    toggle,
    closeAll
  };
};

export default useModal;
