import React from "react";
import { isMobile } from "react-device-detect";

import HeroBackground from "../../assets/Recovery__Illustration.png";

import "./UnAuthHero.scss";

const UnAuthHero = () =>
  !isMobile && (
    <div
      className="UnAuthHero ViewHeight Column Column--50"
      style={{ backgroundImage: "url(" + HeroBackground + ")" }}
    >
      <h1>Recover your wallet from anywhere.</h1>
    </div>
  );

export default UnAuthHero;
