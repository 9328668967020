import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { disableDevice } from "../../utils/DeviceService";
import { CurrentUserContext } from "../../contexts/Store";

const DisableDevice = props => {
  const { deviceId, inModal, setLoading } = props;
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  const [submitted, setSubmitted] = useState(false);
  const [, setSubmitError] = useState(null);
  const history = useHistory();

  const handleComplete = (error = null) => {
    setLoading(false);
    if (error) {
      history.push({
        pathname: "/transaction-error",
        state: { detail: error }
      });
      return false;
    }
    if (inModal) {
      inModal();
    }

  };

  const handleSubmit = async () => {
    setLoading(true);
    setSubmitted(true);
    const [user, error] = await disableDevice(currentUser, deviceId, handleComplete);
    setSubmitError(error);
    setCurrentUser(user);

  }

  return (
    <button
      className="Button--Danger"
      onClick={() => handleSubmit()}
      disabled={submitted}
    >
      Disable Key
    </button>
  );
};

export default DisableDevice;
