export const DeviceStatuses = {
  unknown: "Unknown",
  undefined: "undefined",
  remove: "Remove",
  oldPillarApp: "Old Pillar App",
  pillarApp: "Pillar App",
  pillarGuard: "Pillar Guard",
  oldPillarGuard: "Old Guard can remove",
  paperWallet: "Paper Wallet",
  addPaperWallet: "Add Paper Wallet"
};

export const currentStatus = () => {};
