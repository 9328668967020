import React from "react";
import QrReader from "react-qr-reader";
import { isMobile } from "react-device-detect";

import "./QrCodeScanner.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loading from "../shared/Loading";


const QrCodeScanner = props => {
  const { handleDeviceCapture } = props;

  const handleScan = data => {
    console.log("scanned", data);
  };

  const handleError = () => {
    console.log("qr error");
  };

  return (
    <div className="QrCodeScanner">
      {!isMobile && (
        <>
          <p>Scan the QR code in your Pillar Wallet</p>
          <QrReader
            delay={500}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "80%", margin: "0 auto" }}
          />
        </>
      )}
      <Formik
        validate={values => {
          let errors = {};

          if (!values.deviceAddr) {
            errors.deviceAddr = "Required";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleDeviceCapture(values.deviceAddr);
          return true;
        }}
      >
        {({ isSubmitting, errors, touched }) => {
          if (isSubmitting) {
            return <Loading />;
          }

          return (
            <Form className="Form">
              <Field name="deviceAddr">
                {({ field }) => (
                  <div className={field.value ? "Field HasValue" : "Field "}>
                    {!isMobile && <label>Device Addr</label>}
                    <input type="text" {...field} />
                  </div>
                )}
              </Field>
              <ErrorMessage
                name="deviceAddr"
                render={msg => <div className="Error">{msg}</div>}
              />
              <button
                type="submit"
                className={
                  Object.keys(errors).length < 1 &&
                    Object.keys(touched).length >= 1
                    ? ""
                    : "Disabled"
                }
                disabled={isSubmitting}
              >
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default QrCodeScanner;
