import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";

import Home from "./views/home/Home";
import Help from "./views/help/Help";
import TransactionError from "./views/transaction-error/TransactionError";
import NewDevice from "./views/new-device/NewDevice";
import Confirm from "./views/auth/Confirm";
import SignIn from "./views/auth/SignIn";
import SignUp from "./views/auth/SignUp";
import SignOut from "./views/auth/SignOut";
import ResendCode from "./views/auth/ResendCode";
import FourOhFour from "./views/404/404";

import Loading from "./components/shared/Loading";
import RecoverDevice from "./views/recover-device/RecoverDevice";
import PaperWallet from "./views/paper-wallet/PaperWallet";
import ForgotPassword from "./views/auth/ForgotPassword";
import ForgotPasswordConfirm from "./views/auth/ForgotPasswordConfirm";
import PaperWalletRestore from "./views/paper-wallet/PaperWalletRestore";

const Routes = () => (
  <Switch>
    <PrivateRoute path="/" exact component={Home} />
    <PrivateRoute path="/new-device" exact component={NewDevice} />
    <PrivateRoute path="/recover-device" exact component={RecoverDevice} />
    <PrivateRoute path="/paper-wallet" exact component={PaperWallet} />
    <PrivateRoute path="/paper-wallet-restore" exact component={PaperWalletRestore} />

    <AuthRoute path="/sign-in" exact component={SignIn} />
    <AuthRoute path="/confirm" exact component={Confirm} />
    <AuthRoute
      path="/sign-up/:appName/:accountAddr(\b0x[0-9a-f]{10,40}\b)/:deviceAddr(\b0x[0-9a-f]{10,40}\b)"
      exact
      component={SignUp}
    />
    <AuthRoute path="/resend-code" exact component={ResendCode} />
    <AuthRoute path="/forgot-password" exact component={ForgotPassword} />
    <AuthRoute path="/forgot-password-confirm" exact component={ForgotPasswordConfirm} />

    <Route path="/sign-out" exact component={SignOut} />
    <Route path="/help" exact component={Help} />
    <Route path="/transaction-error" exact component={TransactionError} />
    <Route path="*" component={FourOhFour} />
  </Switch>
);

const PrivateRoute = props => {
  const { component, path } = props;
  const [currentUser, setCurrentUser] = useState(false);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const res = await Auth.currentUserInfo();
      setCurrentUser(res);
      setloading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {currentUser ? (
        <Route path={path} component={component} />
      ) : (
        <Redirect to="/sign-in" />
      )}
    </>
  );
};

const AuthRoute = props => {
  const { component, path } = props;
  const [currentUser, setCurrentUser] = useState(false);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const res = await Auth.currentUserInfo();
      setCurrentUser(res);
      setloading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {!currentUser ? (
        <Route path={path} component={component} />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default Routes;
