import React from 'react';

import { Link } from "react-router-dom";

const FourOhFour = () => (
  <div className="View">
    <p>You're lost.</p>
    <p><Link to="/">Go back home</Link></p>
  </div>
);

export default FourOhFour;
