import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import WelcomeHero from "../../components/shared/WelcomHero";
import QrCodeScanner from "../../components/devices/QrCodeScanner";
import ConfirmRecover from "../../components/devices/ConfirmRecover";
import Loading from '../../components/shared/Loading';

import { DeviceStatuses } from "../../utils/WalletStatus";
import { addDevice } from "../../utils/DeviceService";
import { CurrentUserContext } from "../../contexts/Store";

const RecoverDevice = () => {
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);

  const [deviceId, setDeviceId] = useState();
  const [recovering, setRecovering] = useState(false);
  const history = useHistory();

  const handleDeviceCapture = deviceId => {
    setDeviceId(deviceId);
  };

  useEffect(() => {
    if (!window.ReactNativeWebView) return;
    const listener = (event) => setDeviceId(event.detail.address);
    document.addEventListener('recoveryDeviceAddressAdded', listener)
    window.ReactNativeWebView.postMessage('getRecoveryDeviceAddress');
    return () => document.removeEventListener('recoveryDeviceAddressAdded', listener);
  }, []);

  const handleComplete = (error = null) => {
    if (error) {
      history.push({
        pathname: "/transaction-error",
        state: { detail: error }
      });
      return false;
    }
    history.push({
      pathname: "/"
    });
  };

  const handleConfirmation = async confirmed => {
    if (confirmed) {
      setRecovering(confirmed);
      const [user, ] = await addDevice(
        currentUser,
        deviceId,
        DeviceStatuses.pillarApp,
        handleComplete
      )
      setCurrentUser(user);
    } else {
      setDeviceId(null);
    }
  };

  if (!currentUser || !currentUser.sdk) {
    return <Loading />;
  }

  return (
    <div className="RecoverDevice">
      <WelcomeHero />
      <div className="RecoverDevice__content View">
        <h2>Replace Your Linked Device</h2>
        {!!recovering && <p>Recovering Account</p>}
        {!recovering && (
          <>
            {!deviceId && <QrCodeScanner handleDeviceCapture={handleDeviceCapture} />}
            {!!deviceId && <ConfirmRecover deviceAddress={deviceId} handleConfirmation={handleConfirmation} />}
          </>
        )}
      </div>
    </div>
  );
};

export default RecoverDevice;
