import React, { useState, useEffect } from "react";

import WelcomeHero from "../../components/shared/WelcomHero";
import Web3Service from "../../utils/Web3Service";
import AddDevice from "../../components/devices/AddDevice";
import { DeviceStatuses } from "../../utils/WalletStatus";
import DeviceLoader from "../../components/shared/DeviceLoader";

const PaperWallet = () => {
  const web3Service = new Web3Service();
  const [seedPhrase, setSeedPhrase] = useState("");
  const [randomSeedWords, setRandomSeedWords] = useState([]);
  const [addr, setAddr] = useState();
  const [valid, setValid] = useState(false);
  const [words, setWords] = useState([]);
  const [step, setStep] = useState("new");
  const [loading, setLoading] = useState(false);

  const randomWallet = async () => {
    const rando = await web3Service.createRandom();
    setSeedPhrase(rando.mnemonic);
    setRandomSeedWords(
      rando.mnemonic.split(" ").sort(() => 0.5 - Math.random())
    );
    setAddr(rando.address);
    setStep("writeDown");
    return true;
  };

  const addWord = word => {
    setWords([...words, word]);
    if ([...words, word].join(" ") === seedPhrase) {
      setValid(true);
    }
  };

  const clearWords = () => {
    setWords([]);
  };

  const seedList = () => {
    return seedPhrase.split(" ").map((word, i) => {
      return (
        word && (
          <div className="FauxInput" key={i + 1}>
            {i + 1 + ") " + word}
          </div>
        )
      );
    });
  };

  const wordList = () => {
    return randomSeedWords.map((word, i) => {
      return (
        word && (
          <button
            className="WordList__Button"
            key={i + 1}
            onClick={() => addWord(word)}
            disabled={words.indexOf(word) > -1}
          >
            {word}
          </button>
        )
      );
    });
  };

  const userWordList = () => {
    return words.map((word, i) => {
      return (
        <div className="FauxInput" key={i + 1}>
          {i + 1 + ") " + word}
        </div>
      );
    });
  };

  useEffect(() => {
    randomWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PaperWallet">
      <WelcomeHero />
      <div className="PaperWallet__content View">
        <h2>Create a paper wallet</h2>
        {step === "new" && <DeviceLoader />}
        {step === "writeDown" && !valid && (
          <div className="SmallContainer">
            {seedPhrase && (
              <div>
                <h3>Write down these words in the order they appear.</h3>
                <div className="SeedList">{seedList()}</div>
                <button onClick={() => setValid("true")}>Next</button>
              </div>
            )}
          </div>
        )}
        {step === "verify" && !valid && (
          <div className="GridContainer">
            <div className="Row">
              <h3>
                Click the words in the correct order to verify and proceed.
              </h3>
            </div>
            <div className="Row Grid">
              <div className="Column WordList">
                <button onClick={clearWords} className="Button--Cancel">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                  Clear
                </button>
                {wordList()}
              </div>
              <div className="Column UserWordList">
                {words.length === 12 && (
                  <p className="Danger">Order is incorrect. </p>
                )}
                <div className="SeedGrid">{userWordList()}</div>
                <div>
                  <p>cheat list:</p> {seedPhrase}
                </div>
              </div>
            </div>
          </div>
        )}
        {valid && (
          <div>
            <p>
              Be sure you've written down the Seed Phrase before continuing.
            </p>
            {loading && <DeviceLoader />}
            <AddDevice
              deviceId={addr}
              deviceLabel={DeviceStatuses.paperWallet}
              setLoading={setLoading}
            ></AddDevice>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaperWallet;
