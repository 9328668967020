import React from "react";
import "./ConfirmRecover.scss";

const ConfirmRecover = props => {
  const { handleConfirmation, deviceAddress } = props;
  return (
    <div className="ConfirmRecover">
      <p>This will create a new linked device:</p>
      <p style={{ marginVertical: 50 }}>{deviceAddress}</p>
      <p><strong>Are you sure?</strong></p>
      <div className="ButtonGroup" style={{ alignItems: 'center', justifyContent: 'center' }}>
        <button className="Button--Cancel" style={{ marginRight: 15 }} onClick={() => handleConfirmation(false)}>NO</button>
        <button onClick={() => handleConfirmation(true)}>YES</button>
      </div>
    </div>
  );
};
export default ConfirmRecover;
