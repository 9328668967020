import React from "react";
import HeroBackground from '../../assets/Recovery__Illustration.png';
import "./WelcomeHero.scss";

const WelcomeHero = () => (
  <div className="WelcomeHero" style={{backgroundImage: "url(" + HeroBackground + ")"}}>
    <h1 className="WelcomeHero__headline">Welcome, Human.</h1>
  </div>
);

export default WelcomeHero;
