import { Auth } from "aws-amplify";
import { sdkConstants } from "@smartwallet/sdk";

const gasPriceStrategy = sdkConstants.GasPriceStrategies.Fast;

export const disableDevice = async (currentUser, deviceId, callback) => {
  const sdk = currentUser.sdk;
  const balance = sdk.state.account.balance.real;
  const parsedNamedDevices = JSON.parse(
    currentUser.attributes["custom:named_devices"]
  );
  let error = null;

  try {
    const estimated = await sdk.estimateAccountDeviceUnDeployment(
      deviceId
    );

    if (balance.lt(estimated.gasFee)) {
      throw new Error(
        `you need more ETH for gas, at least: ${estimated.gasFee.toString()} wei`,
      );

    }

    await sdk.submitAccountTransaction(estimated);

    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      "custom:named_devices": JSON.stringify(parsedNamedDevices)
    });
  } catch (err) {
    error = "Error from undeploy: " + err;
    console.log(error);
  }

  const attributes = await Auth.currentUserInfo();
  const realuser = {
    ...currentUser,
    ...{ attributes: attributes.attributes }
  };
  callback(error);
  return [realuser, error];
};

export const removeDevice = async (currentUser, deviceId) => {
  const sdk = currentUser.sdk;

  const parsedNamedDevices = JSON.parse(
    currentUser.attributes["custom:named_devices"]
  );
  let error = null;

  try {
    await sdk.removeAccountDevice(deviceId);

    delete parsedNamedDevices[deviceId];
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, {
      "custom:named_devices": JSON.stringify(parsedNamedDevices)
    });
  } catch (err) {
    error = "Error durring remove: " + err
  }

  const attributes = await Auth.currentUserInfo();
  const realuser = {
    ...currentUser,
    ...{ attributes: attributes.attributes }
  };

  return [realuser, error];
};

export const addDevice = async (
  currentUser,
  deviceId,
  deviceLabel,
  callback
) => {
  const sdk = currentUser.sdk;
  const balance = sdk.state.account.balance.real;
  const parsedNamedDevices = JSON.parse(
    currentUser.attributes["custom:named_devices"]
  );
  const user = await Auth.currentAuthenticatedUser();
  let error = null;

  parsedNamedDevices[deviceId] = deviceLabel;

  try {

    // create new device
    await sdk.createAccountDevice(deviceId);
    // estimate new device tx
    const estimated = await sdk.estimateAccountDeviceDeployment(deviceId, gasPriceStrategy);

    if (balance.lt(estimated.gasFee)) {
      throw new Error(
        `you need more ETH for gas, at least: ${estimated.gasFee.toString()} wei`,
      );
    }

    // submit new deploy device tx, returns hash
    await sdk.submitAccountTransaction(estimated);

  } catch (err) {
    error =  "Error from add: " + err;
    throw new Error(error);
  }
   // update cognito attributes
   await Auth.updateUserAttributes(user, {
    "custom:named_devices": JSON.stringify(parsedNamedDevices)
  });

  const attributes = await Auth.currentUserInfo();
  const realuser = {
    ...currentUser,
    ...{ attributes: attributes.attributes }
  };
  callback(error);
  return [realuser, error];
};

