import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { CurrentUserContext } from "../../contexts/Store";
import WelcomeHero from "../../components/shared/WelcomHero";
import SubNav from "../../components/shared/SubNav";

import Device from "../../components/devices/Device";

import "./Home.scss";
import useInterval from "../../utils/PollingUtil";
import { DeviceStatuses } from "../../utils/WalletStatus";
import WalletActions from "../../components/shared/WalletActions";
import DeviceLoader from "../../components/shared/DeviceLoader";

const Home = () => {
  const [listDevices, setListDevices] = useState([]);
  const [, setUserLoaded] = useState(false);
  const [hasPillarGaurd, setHasPillarGaurd] = useState();
  const [waiting, setWaiting] = useState(false);
  const [accountDisconnected, setAccountDisconnected] = useState(true);
  const [firstTime, setFirstTime] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [delay, setDelay] = useState(null);
  const [delayLength, setDelayLength] = useState(3000);
  const history = useHistory();

  const [currentUser] = useContext(CurrentUserContext);

  const fetchData = async () => {
    let sdkDevices = { items: [] };

    if (firstTime) {
      setLoaded(true);
    }

    if (currentUser) {
      setUserLoaded(true);
    }

    if (currentUser && currentUser.sdk) {
      const sdk = currentUser.sdk;

      // set up empty sdkDevices
      // get sdkDevices from sdk
      try {
        // start getting devices
        sdkDevices = await sdk.getConnectedAccountDevices();
        setAccountDisconnected(false);
        // get sdkDevices from sdk
      } catch (err) {
        console.log(err)
        console.log("account disconnected, add device, redirect to new-dvice");

        history.push("/new-device");
      }

      // get named sdkDevices from currentuser
      const parsedNamedDevices = JSON.parse(
        currentUser.attributes["custom:named_devices"]
      );

      // map sdkDevices to render object
      const devices = [];
      sdkDevices.items.forEach(device => {
        if (device.type === "Owner") {
          // compare against device in user attribute
          const deviceName = parsedNamedDevices[device.device.address];

          devices.push({
            address: device.device.address,
            deviceName: deviceName || "Unknown",
            nextState: device.nextState || "",
            state: device.state || "",
            updatedAt: device.updatedAt
          });
        }
      });

      // check if pillar guard exists
      if (
        devices.some(device => {
          return (
            device.deviceName.indexOf(DeviceStatuses.pillarGuard) > -1 &&
            device.state === "Deployed"
          );
        })
      ) {
        setHasPillarGaurd(true);
      }

      // if any sdkDevices are changing states
      if (sdkDevices.items.some(device => device.nextState)) {
        setWaiting(true);
      } else {
        setWaiting(false);
      }

      setListDevices(devices);
      // set polling interval
      if (!delay) {
        setFirstTime(true);
        setDelay(delayLength);
      }
    }
    if (currentUser && !currentUser.sdk) {
      // no sdk
      if (!delay) {
        setFirstTime(true);
        setDelay(delayLength);
      }
    }
  };

  useInterval(async () => {

    fetchData();
  }, delay);

  useEffect(() => {

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleModal = () => {
    // toggle interval pause
    if (delayLength) {
      setDelay(null);
      setDelayLength(null);
    } else {
      setDelay(3000);
      setDelayLength(3000);
    }
  };

  const renderDevices = () => {
    return listDevices.map(device => {
      return (
        <Device
          device={device}
          waiting={waiting}
          handleModal={handleModal}
          key={device.address}
        />
      );
    });
  };

  return (
    <div className="Home">
      <WelcomeHero />
      <SubNav hasPillarGaurd={!hasPillarGaurd} />
      <div className="Home__content">
        <div>
          {/* only show if devices are loading */}
          {accountDisconnected && !listDevices.length && (
            <div className="FlexCenter">
              <DeviceLoader />
            </div>
          )}
          {renderDevices()}
        </div>

        <div>
          <WalletActions
            devices={listDevices}
            loaded={loaded}
            accountDisconnected={accountDisconnected}
            hasPillarGaurd={hasPillarGaurd}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
