import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import QRCode from "react-qr-code";
import { isMobile } from "react-device-detect";

import { CurrentUserContext } from "../../contexts/Store";
import GreenCheck from "../../assets/GreenCheck.svg";

import "./QrCodeDisplay.scss";
import useInterval from "../../utils/PollingUtil";
import DeviceLoader from "../shared/DeviceLoader";

const QrCodeDisplay = () => {
  const [connected, setConnected] = useState(false);
  const [currentUser] = useContext(CurrentUserContext);
  const [delay, setDelay] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const setUp = async () => {
      if (currentUser && currentUser.sdk && currentUser.sdk.state.account) {
        const sdk = currentUser.sdk;
        const accountAddr = currentUser.attributes["custom:account_address"];
        const connectedAccount = currentUser.sdk.state.account.address;
        const devices = await sdk.getConnectedAccountDevices();
        if (
          devices.items.find(
            item =>
              item.device.address ===
              currentUser.attributes["custom:device_address"] &&
              item.state === "Deployed"
          )
        ) {
          setConnected(accountAddr === connectedAccount);
        }
      }
      if (currentUser) {
        setDelay(3000);
      }
    };

    setUp();
  }, [currentUser]);

  useInterval(async () => {

    connectAccount();
  }, delay);

  const connectAccount = async () => {
    const sdk = currentUser.sdk;
    const accountAddr = currentUser.attributes["custom:account_address"];
    if (!sdk) {
      return;
    }
    try {
      const account = await sdk.connectAccount(accountAddr);
      if (!account) {
      } else {
        setLoading(true);
        const devices = await sdk.getConnectedAccountDevices();
        if (
          devices.items.find(
            item =>
              item.device.address ===
              currentUser.attributes["custom:device_address"] &&
              item.state === "Deployed"
          )
        ) {

          setLoading(false);
          setDelay(null);
          setConnected(true);
        }
      }
    } catch (err) {
      console.log("something wrong with connect", err);
    }
  };

  const onConnectButtonClick = () => {
    if (!window.ReactNativeWebView) return;
    setLoading(true);
    const deviceAddress = currentUser.attributes["custom:device_address"];
    window.ReactNativeWebView.postMessage(JSON.stringify({ deviceAddress }));
  };

  return (
    <div className="View">
      {currentUser && !connected ? (
        <>
          {!isMobile &&
            <>
              <div className="QrCodeDisplay">
                <p>Scan this QR code from your Pillar Wallet</p>
              </div>
              <QRCode value={currentUser.attributes["custom:device_address"]} />
            </>
          }
          {isMobile &&
            <>
              <div className="QrCodeDisplay">
                <p>Connect Recovery Portal as your recovery device</p>
              </div>
              {!loading && (
                <button className="Button" onClick={onConnectButtonClick}>
                  Connect Device
                </button>
              )}
            </>
          }
        </>
      ) : (
          <>
            <img src={GreenCheck} alt="ok" />
            <button
              className="Button--Link"
              style={{ color: "#d756d9" }}
              onClick={() => history.push("/")}
            >
              OK
          </button>
          </>
        )}
      {loading && <DeviceLoader />}
      <p>
        device: {currentUser && currentUser.attributes["custom:device_address"]}
      </p>
      <p>
        account:{" "}
        {currentUser && currentUser.attributes["custom:account_address"]}
      </p>
      {/* {currentUser && !connected && (
        <button onClick={() => connectAccount()}>Connect Account </button>
      )} */}
    </div>
  );
};

export default QrCodeDisplay;
