import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { Auth } from "aws-amplify";

import {
  SdkEnvironmentNames,
  getSdkEnvironment,
  createSdk
} from "@smartwallet/sdk";

import { CurrentUserContext } from "../../contexts/Store";
import Loading from "../../components/shared/Loading";
import Web3Service from "../../utils/Web3Service";
import UnAuthHero from "../../components/shared/UnAuthHero";

import "./Auth.scss";
import { DeviceStatuses } from "../../utils/WalletStatus";

const SignIn = () => {
  const sdkEnv = getSdkEnvironment(
    SdkEnvironmentNames[`${process.env.REACT_APP_SDK_ENV}`]
  ); // kovan env by default
  const [, setCurrentUser] = useContext(CurrentUserContext);
  const [authError, setAuthError] = useState();
  const [pseudonymTouch, setPseudonymTouch] = useState(false);
  const [passwordTouch, setPasswordTouch] = useState(false);
  const history = useHistory();

  let historyState = history.location.state;

  return (
    <div className="Row">
      <UnAuthHero />
      <div className="Column Column--50 ViewHeight">
        <Formik
          initialValues={{ username: "", password: "" }}
          validate={values => {
            let errors = {};
            if (!values.username) {
              errors.username = "Required";
            }
            if (!values.username) {
              errors.password = "Required";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const web3Service = new Web3Service();

            try {
              const user = await Auth.signIn({
                username: values.username,
                password: values.password
              });

              const sdk = new createSdk(
                sdkEnv.setConfig("storageAdapter", localStorage)
              );
              // device address is set to 0x0 on signup
              // update this value after sdk is initialized and created
              if (user.attributes["custom:device_address"] !== "0x0") {
                let key;
                try {
                  key = await web3Service.decryptKeyStore(
                    user.attributes["custom:encrypted_ks"],
                    values.password
                  );
                } catch (err) {
                  // Error: invalid password
                  console.log(err);
                  const sdk = null;
                  setCurrentUser({user, ...{ sdk } });
                  history.push("/");
                  return;
                }
                try {
                  const options = {
                    device: { privateKey: key.privateKey }
                  };

                  await sdk.initialize(options);

                  // initialized

                  const accountAddress =
                    user.attributes["custom:account_address"];
                  const deviceAddress =
                    user.attributes["custom:device_address"];

                  const accountDevices = await sdk.getAccountDevice(
                    accountAddress,
                    deviceAddress
                  );


                  if (accountDevices.state === "Deployed") {
                    await sdk.connectAccount(
                      user.attributes["custom:account_address"]
                    );
                  } else {
                    //unhappy path. user has not added and deployed device in Pillar app
                    console.log("redirct to qr maybe");
                  }
                  //TODO: see if devices is deployed

                  //currentUserInfo returns the correct attributes
                  const attributes = await Auth.currentUserInfo();
                  const realuser = {
                    ...user,
                    ...{ attributes: attributes.attributes }
                  };
                  setCurrentUser({ ...realuser, ...{ sdk } });
                  setSubmitting(false);

                  history.push("/");
                } catch (err) {
                  setCurrentUser({ ...user, ...{ sdk } });
                  history.push("/new-device");
                  console.log(err); // {"error":"account device not found"}
                }
              } else {
                // first time loging in

                await sdk.initialize();

                // have to create a temporary account
                await sdk.createAccount();
                await sdk.getConnectedAccountDevices();

                // create keystore
                const network = process.env.REACT_APP_SDK_ENV.toLowerCase();
                const aValue = JSON.parse(
                  localStorage.getItem(
                    `@smartwallet:${network}:device:private_key`
                  )
                );


                const store = await web3Service.getKeyStore(
                  "0x" + aValue.data,
                  values.password
                );


                // get new device generated by sdk
                const deviceAddr = JSON.parse(
                  localStorage.getItem(`@smartwallet:${network}:account_device`)
                );

                let attributes = await Auth.currentUserInfo();
                const parsedNamedDevices = JSON.parse(
                  attributes.attributes["custom:named_devices"]
                );
                parsedNamedDevices[deviceAddr.device.address] =
                  DeviceStatuses.pillarGuard;

                await Auth.updateUserAttributes(user, {
                  // "custom:account_address": appDeepLink.accountAddr,
                  "custom:device_address": deviceAddr.device.address,
                  //"custom:ens_name": ensLabel,
                  "custom:named_devices": JSON.stringify(parsedNamedDevices),
                  "custom:encrypted_ks": JSON.stringify(store)
                });

                attributes = await Auth.currentUserInfo();

                const realuser = {
                  ...user,
                  ...{ attributes: attributes.attributes }
                };
                setCurrentUser({ ...realuser, ...{ sdk } });

                setSubmitting(false);

                history.push({
                  pathname: "/new-device"
                });
              }
            } catch (err) {
              setAuthError(err);
              setSubmitting(false);
              console.log("error signing in: ", err);
            }
          }}
        >
          {({ isSubmitting, errors, touched }) => {
            if (isSubmitting) {
              return <Loading />;
            }

            return (
              <Form className="Form">
                {historyState && historyState.msg && (
                  <div className="Validated">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z" />
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                    </svg>{" "}
                    {historyState.msg}
                  </div>
                )}
                <h2>Sign in</h2>
                {authError && (
                  <div className="Form__auth-error">
                    <p className="Danger">{authError.message}</p>
                  </div>
                )}
                <Field name="username">
                  {({ field, form }) => (
                    <div className={field.value ? "Field HasValue" : "Field "}>
                      <label>Pseudonym</label>
                      <input
                        type="text"
                        {...field}
                        onInput={() => setPseudonymTouch(true)}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="username"
                  render={msg => <div className="Error">{msg}</div>}
                />
                <Field type="password" name="password">
                  {({ field, form }) => (
                    <div className={field.value ? "Field HasValue" : "Field "}>
                      <label>Password</label>
                      <input
                        type="password"
                        {...field}
                        onInput={() => setPasswordTouch(true)}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="password"
                  render={msg => <div className="Error">{msg}</div>}
                />
                <div className="ButtonGroup">
                  <button
                    type="submit"
                    className={
                      Object.keys(errors).length < 1 &&
                      pseudonymTouch &&
                      passwordTouch
                        ? ""
                        : "Disabled"
                    }
                    disabled={isSubmitting}
                  >
                    Sign In
                  </button>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SignIn;
