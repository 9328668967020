import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import { CurrentUserContext } from "../../contexts/Store";

const SignOut = () => {
  const [, setCurrentUser] = useContext(CurrentUserContext);
  const history = useHistory();

  useEffect(() => {
    const currentUser = async () => {
      try {
        await Auth.signOut();
        setCurrentUser(null);
        localStorage.clear();
        history.push("/sign-in");
      } catch {
        console.log("error on signout");
      }
    };

    currentUser();

 //eslint-disable-next-line
 }, [setCurrentUser]);

  return <></>;
};

export default SignOut;
