import React from "react";
import { useHistory } from "react-router-dom";

import Modal from "./Modal";
import useModal from "./UseModal";

const RestoreFromSeed = () => {
  const { isShowing, toggle } = useModal();
  const history = useHistory();

  return (
    <div>
      <div className="Device">
        <div className="Device__Details">
          <h4>Restore From Paper Wallet</h4>
          {/* <small>{device.address}</small> */}
        </div>
        <button onClick={() => toggle("restorePaperWallet")}>Restore</button>
        <Modal
          isShowing={isShowing.restorePaperWallet}
          hide={() => toggle("restorePaperWallet")}
        >
          <h3>Paper Wallet</h3>
          <p>You must restore from paper wallet.</p>
          <div className="ModalActions">
            <button onClick={() => history.push("/paper-wallet-restore")}>
              Restore
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default RestoreFromSeed;
