import React, {useContext} from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import './App.scss';

import Loading from './components/shared/Loading';
import Header from './components/header/Header';
import Routes from './Routes';
import { LoaderContext } from './contexts/Store';

function App() {
  const [loading ] = useContext(LoaderContext)
  return (
    <div className="App">
    {loading ? (
      <Loading />
    ) : (
      <Router forceRefresh>
        <Header />
        <Routes />
      </Router>
    )}
  </div>
  );
}

export default App;
