import React from "react";
import { Link } from "react-router-dom";

import "./SubNav.scss";

const SubNav = (props) => {
  const {hasPillarGaurd} = props;
  return (
    <div className="SubNav">
      <div className="SubNav__Inner">
        <Link to="/" className="Active">
          Manage Keys
        </Link>
        <Link style={hasPillarGaurd ? {pointerEvents: "none"} : null} to="/recover-device">Recover</Link>
      </div>
    </div>
  );
};
export default SubNav;
