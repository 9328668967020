import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Auth } from "aws-amplify";

import Loading from "../../components/shared/Loading";
import UnAuthHero from "../../components/shared/UnAuthHero";
import { AppDeepLink } from "../../contexts/Store";
import { DeviceStatuses } from "../../utils/WalletStatus";

const SignUp = () => {
  const [authError, setAuthError] = useState();
  const [appDeepLink, setAppDeepLink] = useContext(AppDeepLink);
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    // example:
    // http://localhost:3000/sign-up/piller/0xfC7950Ec48bf21BbFCAca07A83Ad99a8f8Fe900
    const appName = match.params.appName;
    const accountAddr = match.params.accountAddr;
    const deviceAddr = match.params.deviceAddr;
    setAppDeepLink({
      appName,
      accountAddr,
      deviceAddr
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Row">
      <UnAuthHero />
      <div className="Column Column--50 ViewHeight NoMobilePadding">
        <Formik
          initialValues={{
            username: "",
            email: "",
            password: "",
            passwordConfirm: ""
          }}
          validate={values => {
            let errors = {};
            const regexPasswordValidation = new RegExp(
              "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&.,])\\S*$"
            );
            if (!values.username) {
              errors.username = "Required";
            }
            if (!values.email) {
              errors.email = "Required";
            }
            if (!values.password) {
              errors.password = "Required";
            }
            if (values.password.length < 8) {
              errors.password = "Password must be at least 8 characters long";
            }
            if (!regexPasswordValidation.test(values.password)) {
              errors.password =
                "Password must contain an uppercase letter, a lowercase letter, a number and a special character";
            }
            if (!values.passwordConfirm) {
              errors.passwordConfirm = "Required";
            }
            if (values.password !== values.passwordConfirm) {
              errors.passwordConfirm = "Passwords do not match";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            // set custom attributes to 0x0 as place holder
            try {
              await Auth.signUp({
                username: values.username,
                password: values.password,
                attributes: {
                  email: values.email,
                  "custom:account_address": appDeepLink.accountAddr,
                  "custom:device_address": "0x0",
                  "custom:named_devices": JSON.stringify({
                    [appDeepLink.deviceAddr]: DeviceStatuses.pillarApp
                  })
                }
              });
              history.push({
                pathname: "/confirm",
                state: { userName: values.username }
              });
            } catch (err) {
              console.log("error signing up: ", err);
              setSubmitting(false);
              setAuthError(err);
            }
          }}
        >
          {({ isSubmitting, errors, touched }) => {
            if (isSubmitting) {
              return <Loading />;
            }

            return (
              <Form className="Form NoMobilePadding">
                <h2>New Account</h2>
                {authError && (
                  <div className="Form__auth-error">{authError.message}</div>
                )}
                <Field name="username">
                  {({ field, form }) => (
                    <div className={field.value ? "Field HasValue" : "Field "}>
                      <label>Pseudonym</label>
                      <input type="text" {...field} />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="username"
                  render={msg => <div className="Error">{msg}</div>}
                />
                <Field name="email">
                  {({ field, form }) => (
                    <div className={field.value ? "Field HasValue" : "Field "}>
                      <label>Email</label>
                      <input type="email" {...field} />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="email"
                  render={msg => <div className="Error">{msg}</div>}
                />
                <Field name="password">
                  {({ field, form }) => (
                    <div className={field.value ? "Field HasValue" : "Field "}>
                      <label>Password</label>
                      <input type="password" {...field} />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="password"
                  render={msg => <div className="Error">{msg}</div>}
                />
                <Field name="passwordConfirm">
                  {({ field, form }) => (
                    <div className={field.value ? "Field HasValue" : "Field "}>
                      <label>Confirm password</label>
                      <input type="password" {...field} />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="passwordConfirm"
                  render={msg => <div className="Error">{msg}</div>}
                />
                <button
                  type="submit"
                  className={
                    Object.keys(errors).length < 1 &&
                    Object.keys(touched).length > 2
                      ? ""
                      : "Disabled"
                  }
                  disabled={isSubmitting}
                >
                  Submit
                </button>
                <Link to="/sign-in">Already have account? Sign In</Link>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SignUp;
