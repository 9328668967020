import { ethers } from 'ethers';

export default class Web3Service {


  getKeyStore(privateKey, password){
    let wallet = new ethers.Wallet(privateKey);
    return wallet.encrypt(password);
  }

  decryptKeyStore(keystore, password){
    return ethers.Wallet.fromEncryptedJson(JSON.parse(keystore), password);
  }

  fromMnemonic(mnemonic){
    return ethers.Wallet.fromMnemonic(mnemonic);
    //fromMnemonic
    
  }

  createRandom() {
    return ethers.Wallet.createRandom();
    
  }


}
