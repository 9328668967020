import React from "react";

import WelcomeHero from "../../components/shared/WelcomHero";
import QrCodeDisplay from "../../components/devices/QrCodeDisplay";

const NewDevice = () => {
  return (
    <div className="NewDevice">
      <WelcomeHero />
      <div className="NewDevice__content View">
        <h2>Link Your Primary Device</h2>

        <QrCodeDisplay />
      </div>
    </div>
  );
};

export default NewDevice;
