import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { CurrentUserContext } from "../../contexts/Store";

const TransactionError = () => {
    const location = useLocation();
    const [currentUser,] = useContext(CurrentUserContext);

    return (<div>
        <p>Something Went wrong with your transaction. Make sure you have enough gas and try again.</p>
        {currentUser && <p>account: {currentUser.attributes['custom:account_address']}</p>}
        {location && location.state && location.state.detail && <p>Detail: {location.state.detail}</p>}
        <button>Contact Support</button>
    </div>)
};

export default TransactionError;
