import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Auth } from "aws-amplify";

import {
  SdkEnvironmentNames,
  getSdkEnvironment,
  createSdk
} from "@smartwallet/sdk";

import WelcomeHero from "../../components/shared/WelcomHero";
import Web3Service from "../../utils/Web3Service";
import { CurrentUserContext } from "../../contexts/Store";
import { addDevice } from "../../utils/DeviceService";
import { DeviceStatuses } from "../../utils/WalletStatus";
import DeviceLoader from "../../components/shared/DeviceLoader";

const PaperWalletRestore = () => {
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  const [, setOldGuard] = useState();
  const [step, setStep] = useState("new");
  const [loading] = useState(false);
  const [authError, setAuthError] = useState();
  const history = useHistory();

  const sdkEnv = getSdkEnvironment(
    SdkEnvironmentNames[`${process.env.REACT_APP_SDK_ENV}`]
  );

  const renderInputs = () => {
    return [...Array(12).keys()].map(num => {
      return (
        <Field key={num} name={"keyValue" + (num + 1)}>
          {({ field, form }) => (
            <div className={field.value ? "Field HasValue" : "Field "}>
              <label>Word #{num + 1 + ""}</label>
              {/*num + 1 + ") "*/}
              <input type="text" {...field} />
            </div>
          )}
        </Field>
      );
    });
  };

  const getKeys = nemonic => {
    const web3Service = new Web3Service();
    return web3Service.fromMnemonic(nemonic);
  };

  const handleComplete = (error = null) => {
    if (error) {
      history.push({
        pathname: "/transaction-error",
        state: { detail: error }
      });
      return false;
    }
  };

  const initSdk = async key => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const sdk = new createSdk(
        sdkEnv.setConfig("storageAdapter", localStorage)
      );

      const options = {
        device: { privateKey: key.privateKey }
      };

      await sdk.initialize(options);
      //initialized

      const accountAddress = user.attributes["custom:account_address"];
      const deviceAddress = user.attributes["custom:device_address"];

      const accountDevices = await sdk.getAccountDevice(
        accountAddress,
        deviceAddress
      );

      if (accountDevices.state === "Deployed") {
        //connecting to account with paper wallet

        const connected = await sdk.connectAccount(
          user.attributes["custom:account_address"]
        );
        if (!connected) {
          console.log("valid seed but wrong account");
          return null;
        }
      } else {
        //unhappy path. user has not added and deployed device in Pillar app
        console.log("wrong account");
      }
      //TODO: see if devices is deployed

      //currentUserInfo returns the correct attributes
      const attributes = await Auth.currentUserInfo();
      const realuser = {
        ...user,
        ...{ attributes: attributes.attributes }
      };
      return { ...realuser, ...{ sdk } };
    } catch (err) {
      console.log(err);
      //return null;
    }
  };

  return (
    <div className="PaperWalletRestore">
      <WelcomeHero />
      <div className="NewDevice__content View">
        {step === "new" && (
          <>
            <h2>Enter 12 word seed phrase</h2>
            <Formik
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  let seed = "";
                  if (values.cheat === "") {
                    seed = values.cheat;
                  } else {
                    for (const property in values) {
                      if (property === "keyValue1" || property === "cheat") {
                        seed += values[property];
                      } else {
                        seed += " " + values[property];
                      }
                    }
                  }

                  const keys = getKeys(seed);
                  const user = await initSdk(keys);
                  if (user) {
                    setCurrentUser(user);
                    setStep("addPrimary");
                  } else {
                    setStep("new");
                  }

                  setSubmitting(false);
                } catch (err) {
                  //authError = err;
                  console.log(err);

                  setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting }) => {
                // if (isSubmitting) {
                //   return <Loading />;
                // }

                return (
                  <Form className="Form SeedForm">
                    <div className="SeedGrid">{renderInputs()}</div>
                    <Field name="cheat">
                      {({ field, form }) => (
                        <div
                          className={field.value ? "Field HasValue" : "Field "}
                        >
                          <label>cheat copy paste</label>
                          <textarea {...field} />
                        </div>
                      )}
                    </Field>
                    {isSubmitting && <DeviceLoader />}
                    <button type="submit" disabled={isSubmitting}>
                      restore
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
        {step === "addPrimary" && (
          <>
            <Formik
              initialValues={{
                oldPassword: ""
              }}
              validate={values => {
                let errors = {};

                if (!values.oldPassword) {
                  errors.oldPassword = "Required";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                const web3Service = new Web3Service();

                try {
                  const user = await Auth.currentAuthenticatedUser();

                  await Auth.changePassword(
                    user,
                    values.oldPassword,
                    values.oldPassword
                  );

                  const parsedNamedDevices = JSON.parse(
                    currentUser.attributes["custom:named_devices"]
                  );

                  for (var _device in parsedNamedDevices) {

                    if (
                      parsedNamedDevices[_device].indexOf(
                        DeviceStatuses.pillarGuard
                      ) > -1
                    ) {
                      setOldGuard(_device);
                      parsedNamedDevices[_device] =
                        DeviceStatuses.oldPillarGuard;
                    }
                  }

                  await Auth.updateUserAttributes(user, {
                    "custom:named_devices": JSON.stringify(parsedNamedDevices)
                  });
                  const _attributes = await Auth.currentUserInfo();
                  const realuser = {
                    ...currentUser,
                    ...{ attributes: _attributes.attributes }
                  };

                  const newDevice = await web3Service.createRandom();


                  const store = await web3Service.getKeyStore(
                    newDevice.privateKey,
                    values.oldPassword
                  );

                  const deviceLabel = DeviceStatuses.pillarGuard;

                  const [updatedUser, ] = await addDevice(
                    realuser,
                    newDevice.address,
                    deviceLabel,
                    handleComplete
                  );

                  await Auth.updateUserAttributes(user, {
                    "custom:encrypted_ks": JSON.stringify(store),
                    "custom:device_address": newDevice.address
                  });
                  const attributes = await Auth.currentUserInfo();
                  setCurrentUser({
                    ...updatedUser,
                    ...{ attributes: attributes.attributes }
                  });
                  setSubmitting(false);
                  setStep("deviceAdded");
                } catch (err) {
                  //error changing pass
                  setSubmitting(false);
                  setAuthError(err);
                }
              }}
            >
              {({ isSubmitting, errors, touched }) => {


                return (
                  <Form className="Form">
                    <div className="Validated">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path fill="none" d="M0 0h24v24H0V0z" />
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                      </svg>{" "}
                      Seed is Valid
                    </div>
                    <h2>Enter your password to confirm.</h2>
                    {authError && (
                      <div className="Form__auth-error">
                        {authError.message}
                      </div>
                    )}

                    <Field name="oldPassword">
                      {({ field, form }) => (
                        <div
                          className={field.value ? "Field HasValue" : "Field "}
                        >
                          <label>Enter password</label>
                          <input type="password" {...field} />
                        </div>
                      )}
                    </Field>
                    <ErrorMessage
                      name="oldPassword"
                      render={msg => <div className="Error">{msg}</div>}
                    />
                    {isSubmitting && <DeviceLoader />}
                    <button
                      type="submit"
                      className={
                        Object.keys(errors).length < 1 &&
                          Object.keys(touched).length >= 1
                          ? ""
                          : "Disabled"
                      }
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
        {step === "deviceAdded" && (
          <>
            <h2>Seed Restore completed successfully.</h2>
            <button
              onClick={() => {
                // removing device here does not work, it puts device stuck in nextState
                // probably will have to do an interval and watch for tx to finish

                // redirect home

                window.location.href = "/";
              }}
              disabled={loading}
            >
              Home
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PaperWalletRestore;
