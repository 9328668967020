import React, { useState, useEffect, createContext } from "react";
import { Auth } from "aws-amplify";
import {
  SdkEnvironmentNames,
  getSdkEnvironment,
  createSdk
} from "@smartwallet/sdk";

export const LoaderContext = createContext(false);
export const CurrentUserContext = createContext();
export const AppDeepLink = createContext();

// main store of global state
const Store = ({ children }) => {
  // used to store current users auth and sdk instance
  const [currentUser, setCurrentUser] = useState();
  // used to store values passed in from external app
  const [appDeepLink, setAppDeepLink] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // runs on app load, sets up user auth and sdk
    const initCurrentUser = async () => {
      try {
        // check if user is authenticated
        // try will throw if not
        const user = await Auth.currentAuthenticatedUser();
        // attributes are only updated here until re-auth
        // so grab attributes from here
        const attributes = await Auth.currentUserInfo();
        const realuser = { ...user, ...{ attributes: attributes.attributes } };
        setCurrentUser(realuser);

        // attach sdk
        const sdkEnv = getSdkEnvironment(
          SdkEnvironmentNames[`${process.env.REACT_APP_SDK_ENV}`]
        );

        // check or set up local storage and initialize sdk connection
        let sdk = new createSdk(
          sdkEnv.setConfig("storageAdapter", localStorage)
        );
        await sdk.initialize();
        // check if account is connected in local storage

        //TODO: will error here
        const accounts = await sdk.getConnectedAccounts();

        if (!accounts.items[0]) {
          sdk = null;
          setCurrentUser({ ...realuser, ...{ sdk } });

          console.log('no accounts, sdk not unlocked')
          return
        }

        // reconnect to first account, may be dummy if not deployed
        const accountAddress = accounts.items[0].address;
        const deviceAddress = attributes.attributes["custom:device_address"];
        // if the there is an account connect it
        // this should never not exsist, it is added to AWS on first signin
        const accountDevice = await sdk.getAccountDevice(
          accountAddress,
          deviceAddress
        );

        // console.log('?accountDevice', accountDevice);
        // console.log("?accounts", accounts);

        if (accountDevice && accountDevice.state === "Deployed") {
          await sdk.connectAccount(user.attributes["custom:account_address"]);
        } else {
          //unhappy path. user has not added and deployed device in Pillar app

          if (window.location.pathname !== "/new-device") {
            // window.location.href = "/new-device"
            console.log('redirect to new-device');

          }
          // await sdk.createAccount()
        }

        // store sdk instance (needed?)
        //setUserSdk(sdk);
        // add sdk instance to current user
        setCurrentUser({ ...realuser, ...{ sdk } });
      } catch (err) {
        console.log(err);
      }
    };

    initCurrentUser();
  }, []);

  return (
    <LoaderContext.Provider value={[loading, setLoading]}>
      <CurrentUserContext.Provider value={[currentUser, setCurrentUser]}>
        <AppDeepLink.Provider value={[appDeepLink, setAppDeepLink]}>
          {children}
        </AppDeepLink.Provider>
      </CurrentUserContext.Provider>
    </LoaderContext.Provider>
  );
};

export default Store;
