import React from "react";
import { useHistory } from "react-router-dom";

import Modal from "./Modal";
import useModal from "./UseModal";
import { DeviceStatuses } from "../../utils/WalletStatus";
import RestoreFromSeed from "./RestoreFromSeed";

const WalletActions = props => {
  const { devices, hasPillarGaurd, accountDisconnected, loaded } = props;
  const { isShowing, toggle } = useModal();
  const history = useHistory();

  const hasPaperWallet = () => {
    return devices.some(device => {
      return device.deviceName.indexOf(DeviceStatuses.paperWallet) > -1;
    });
  };

  return (
    <div>
      {/* only show if no paper wallet and connected */}
      {devices && !hasPaperWallet() && hasPillarGaurd && (
        <div className="Device">
          <div className="Device__Details">
            <h4>Paper Wallet </h4>
            <p className="Device__Details--Address">
              ! Required if you forget your password !
            </p>
          </div>
          <button onClick={() => toggle("addPaperWallet")}>Add</button>
          <Modal
            isShowing={isShowing.addPaperWallet}
            hide={() => toggle("addPaperWallet")}
          >
            <h3>Paper Wallet</h3>
            <p>
              Adding a paper wallet helps recover your account in case you
              forget your password.
            </p>
            <div className="ModalActions">
              <button onClick={() => history.push("/paper-wallet")}>
                Add Paper Wallet
              </button>
            </div>
          </Modal>
        </div>
      )}

      {/* only show if not connected to pillar app */}
      {!hasPillarGaurd && !devices && (
        <div className="Device">
          <div className="Device__Details">
            <h4>Get Started with New Key</h4>
            {/* <small>{device.address}</small> */}
          </div>
          <button onClick={() => history.push("/new-device")}>New Key </button>
        </div>
      )}

      {/* only  show if broken */}
      {accountDisconnected && !devices && (
        <div className="Device">
          <div className="Device__Details">
            <h4>SDK not loaded</h4>
            {/* <small>{device.address}</small> */}
          </div>
          <button onClick={() => history.push("/new-device")}>
            new{" "}
          </button>
        </div>
      )}

      {/* only show if not connected */}
      <div>{accountDisconnected && loaded && <RestoreFromSeed />}</div>
    </div>
  );
};

export default WalletActions;
