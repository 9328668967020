import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { addDevice } from "../../utils/DeviceService";
import { CurrentUserContext } from "../../contexts/Store";

const AddDevice = props => {
  const { deviceId, deviceLabel, inModal, setLoading } = props;
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  const [submitted, setSubmitted] = useState(false);
  const [, setSubmitError] = useState(null);
  const history = useHistory();

  const handleComplete = (error = null) => {
    setLoading(false);

    if (error) {
      history.push({
        pathname: "/transaction-error",
        state: { detail: error }
      });
      return false;
    }
    if (inModal) {
      inModal();
    }
    history.push({
      pathname: "/"
    });
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    setLoading(true);
    const [user, error] = await addDevice(
      currentUser,
      deviceId,
      deviceLabel,
      handleComplete
    );
    setSubmitError(error);
    setCurrentUser(user);
  };

  return (
    <button
      disabled={submitted}
      onClick={() => {
        handleSubmit();
      }}
    >
      Enable Key
    </button>
  );
};

export default AddDevice;
