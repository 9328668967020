import React from "react";
import ReactDOM from "react-dom";
import Amplify from 'aws-amplify';

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Store from "./contexts/Store";

import "./index.css";

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
});

const Index = () => (
  <Store>
    <App />
  </Store>
);

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
