import React, { useContext, useState } from "react";

import { removeDevice } from "../../utils/DeviceService";
import { CurrentUserContext } from "../../contexts/Store";

const RemoveDevice = props => {
  const { deviceId, modalLoading } = props;
  const [currentUser, setCurrentUser] = useContext(CurrentUserContext);
  const [submitted, setSubmitted] = useState(false);

  const removeOldDevice = async () => {
    setSubmitted(true);
    const [user, ] = await removeDevice(currentUser, deviceId);
    setCurrentUser(user);
  };

  return (
    <button
      className="Button--Danger"
      onClick={() => removeOldDevice()}
      disabled={submitted || modalLoading}
    >
      Remove Key
    </button>
  );
};

export default RemoveDevice;
