import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./Device.scss";
import RemoveDevice from "./RemoveDevice";
import DisableDevice from "./DisableDevice";
import AddDevice from "./AddDevice";
import DeviceLoader from "../shared/DeviceLoader";

import { DeviceStatuses } from "../../utils/WalletStatus";
import Modal from "../shared/Modal";

import useModal from "../shared/UseModal";
import { truncateAddr } from "../../utils/Helpers";

const Device = props => {
  const { device, waiting, handleModal } = props;
  const { isShowing, toggle, closeAll } = useModal();
  const [modalLoading, setModalLoading] = useState(false);
  const history = useHistory();

  const closeAllWrapper = (id) => {
    handleModal();
    closeAll();
  }

  const toggleWrapper = (id) => {
    handleModal();
    toggle(id);
  }

  switch (true) {
    case device.deviceName === DeviceStatuses.unknown:
      return (
        <div className="Device">
          <div className="Device__Details">
            <h4>{device.deviceName}</h4>
            <p className="Device__Details--Address">
              {truncateAddr(device.address)}
            </p>
          </div>
          <button disabled={waiting} onClick={() => toggleWrapper("info")}>
            settings
          </button>
          <Modal isShowing={isShowing.info} hide={() => toggleWrapper("info")}>
            {device.state === "Created" && (
              <div className="ModalActions">
                <AddDevice
                  deviceId={device.address}
                  deviceLabel={device.deviceName}
                  inModal={closeAllWrapper}
                  setLoading={setModalLoading}
                />
                <RemoveDevice deviceId={device.address} modalLoading={modalLoading} />
              </div>
            )}
            {device.state === "Deployed" && (
              <div className="ModalActions">
                <DisableDevice
                  deviceId={device.address}
                  inModal={closeAllWrapper}
                  setLoading={setModalLoading}
                />
              </div>
            )}
          </Modal>
        </div>
      );
    case device.deviceName.indexOf(DeviceStatuses.paperWallet) > -1:
      return (
        <div className="Device">
          <div className="Device__Details">
            {device.state === "Created" && !device.nextState && (
              <p className="Device__Details--State IsNotConnected">
                Required if you forget your password
              </p>
            )}
            {device.nextState && (
              <p className="Device__Details--State IsConnecting">
                <DeviceLoader /> Processing
              </p>
            )}
            {device.state === "Deployed" && !device.nextState && (
              <p className="Device__Details--State IsConnected">Active</p>
            )}
            <h4>{device.deviceName}</h4>
            <p className="Device__Details--Address">
              {truncateAddr(device.address)}
            </p>
          </div>
          <button disabled={waiting} onClick={() => toggleWrapper("pwSettings")}>
            Settings
          </button>

          <Modal
            isShowing={isShowing.pwSettings}
            hide={() => toggleWrapper("pwSettings")}
          >
            <h3>Paper Wallet</h3>
            <p>
              Adding a paper wallet helps recover your account in case you
              forget your password.
            </p>
            {modalLoading && <DeviceLoader />}
            <button onClick={() => history.push("/paper-wallet")} disabled={modalLoading}>New</button>
            {device.state === "Created" && (
              <div className="ModalActions">
                <AddDevice
                  deviceId={device.address}
                  deviceLabel={device.deviceName}
                  inModal={closeAllWrapper}
                  setLoading={setModalLoading}
                />
                <RemoveDevice deviceId={device.address} modalLoading={modalLoading} />
              </div>
            )}
            {device.state === "Deployed" && (
              <div className="ModalActions">
                <DisableDevice
                  deviceId={device.address}
                  inModal={closeAllWrapper}
                  setLoading={setModalLoading}
                />
              </div>
            )}
          </Modal>
        </div>
      );
    case device.deviceName === DeviceStatuses.pillarGuard:
      return (
        <div className="Device">
          <div className="Device__Details">
            {device.state === "Created" && !device.nextState && (
              <p className="Device__Details--State IsNotConnected">Disabled</p>
            )}
            {device.nextState && (
              <p className="Device__Details--State IsConnecting">
                <DeviceLoader /> Processing
              </p>
            )}
            {device.state === "Deployed" && !device.nextState && (
              <p className="Device__Details--State IsConnected">Active</p>
            )}
            <h4>{device.deviceName} </h4>
            <p className="Device__Details--Address">
              {truncateAddr(device.address)}
            </p>
          </div>
          <button disabled={waiting} onClick={() => toggleWrapper("info")}>
            Info
          </button>
          <Modal isShowing={isShowing.info} hide={() => toggleWrapper("info")}>
            <h3>Pillar Guard</h3>
            <p>Pillar Guard protects your primary key and cannot be removed.</p>
            <div className="ModalActions">
              {device.state === "Created" ? (
                <button onClick={() => history.push("/new-device")}>
                  Connect Pillar App
                </button>
              ) : (
                <button onClick={() => toggleWrapper("info")}>OK</button>
              )}
            </div>
          </Modal>
        </div>
      );
    case device.deviceName === DeviceStatuses.oldPillarGuard:
      return null;
    default:
      return (
        <div className="Device">
          <div className="Device__Details">
            {device.state === "Created" && !device.nextState && (
              <p className="Device__Details--State IsNotConnected">Disabled</p>
            )}
            {device.nextState && (
              <p className="Device__Details--State IsConnecting">
                <DeviceLoader /> Processing
              </p>
            )}
            {device.state === "Deployed" && !device.nextState && (
              <p className="Device__Details--State IsConnected">Active</p>
            )}
            <h4>{device.deviceName}</h4>
            <p className="Device__Details--Address">
              {truncateAddr(device.address)}
            </p>
          </div>
          <button disabled={waiting} onClick={() => toggleWrapper("settings")}>
            Settings
          </button>
          <Modal isShowing={isShowing.settings} hide={() => toggleWrapper("settings")}>
            <h3>Pillar App</h3>
            <p>
              If you lost your device, you can disable this key to secure your
              funds. After disabling, you can add it back if you find your
              device, or just remove it completely.
            </p>
            {modalLoading && <DeviceLoader />}
            {/*<button onClick={() => toggleWrapper("settings")}>OK</button>*/}
            {device.state === "Created" && (
              <div className="ModalActions">
                <AddDevice
                  deviceId={device.address}
                  deviceLabel={device.deviceName}
                  inModal={closeAllWrapper}
                  setLoading={setModalLoading}
                />
                <RemoveDevice deviceId={device.address} modalLoading={modalLoading} />
              </div>
            )}
            {device.state === "Deployed" && (
              <div className="ModalActions">
                <DisableDevice
                  deviceId={device.address}
                  inModal={closeAllWrapper}
                  setLoading={setModalLoading}
                />
              </div>
            )}
          </Modal>
        </div>
      );
  }
};

export default Device;
